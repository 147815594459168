var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isOpen)?_c('RightSideBar',{attrs:{"close-button":"","loading":_vm.isLoading,"button-class":"bg-dynamicBackBtn text-white","width":"width:520px"},on:{"close":function($event){_vm.isOpen=false}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"flex justify-start items-center"},[_c('BackButton',{attrs:{"variant":"secondary"},on:{"onClick":function($event){_vm.isOpen=false}}}),_c('p',{staticClass:"text-darkPurple font-bold text-lg leading-5 p-0 -ml-2"},[_vm._v(" Approval Status ")])],1)]},proxy:true},(_vm.approvers.length)?{key:"subtitle",fn:function(){return [[_c('div',{staticClass:"flex flex-col justify-start gap-5"},[_c('div',{staticClass:"grid grid-cols-2 gap-2"},_vm._l((_vm.statuses),function(status,i){return _c('div',{key:`${status}-${i}`},[_c('div',{staticClass:"rounded-md p-3 flex justify-start items-center gap-2 w-full",class:_vm.approvalStatus === status ? 'r-active' : 'r-inactive'},[_c('Icon',{attrs:{"icon-name":"icon-display-grid","class-name":"text-romainSilver","size":"xs"}}),_c('p',{staticClass:"text-sm leading-5 capitalize"},[_vm._v(" "+_vm._s(status.split("_").join(" "))+" ")])],1)])}),0),_c('div',{staticClass:"mt-2"},[_c('p',{staticClass:"font-normal text-sm text-shinyBlack"},[_vm._v(" Track the status of this approval here. See who has approved and who is yet to approve. ")])]),_c('div',{staticClass:"mb-3"},[_c('p',{staticClass:"font-bold text-xs leading-5 text-darkPurple uppercase"},[_vm._v(" "+_vm._s(_vm.approvalStatus.split("_").join(" "))+" ")])])])]]},proxy:true}:null],null,true)},[(_vm.approvers.length)?[_c('div',{staticClass:"flex flex-col gap-3"},_vm._l((_vm.approvers),function(approver){return _c('div',{key:approver.userId},[_c('Card',{staticClass:"2-full p-3 flex justify-between items-center"},[_c('div',{staticClass:"w-full border-l-4",class:{
              'border-desire': approver.hasApproved === 'false',
              'border-mediumSeaGreen': approver.hasApproved === 'true',
              'border-accentSquash': approver.currentApprover === 'active',
              'border-romanSilver': approver.currentApprover === 'inactive' || 'cancelled',
            }},[_c('div',{staticClass:"w-full flex flex-col gap-2"},[_c('div',{staticClass:"w-full flex justify-between items-center"},[_c('div',{staticClass:"flex justify-start items-start gap-2 ml-2"},[(approver.photo)?_c('div',{staticClass:"rounded-md w-9 h-9"},[_c('img',{staticClass:"rounded-md w-9 h-9",attrs:{"alt":"approver photo","src":approver.photo}})]):_c('div',{staticClass:"rounded-md w-9 h-9 border flex justify-center items-center"},[_c('span',{staticClass:"text-center font-bold text-sm text-blueCrayola"},[_vm._v(" "+_vm._s(_vm.$getInitials(`${approver.fname} ${approver.lname}`))+" ")])]),_c('div',{staticClass:"flex flex-col justify-start gap-2"},[_c('p',{staticClass:"font-normal text-xs text-desire capitalize"},[_vm._v(" Approver ")]),_c('div',{staticClass:"flex flex-col justify-start"},[_c('p',{staticClass:"font-normal text-sm text-darkPurple capitalize"},[_vm._v(" "+_vm._s(approver.fname)+" "+_vm._s(approver.lname)+" ")]),_c('p',{staticClass:"font-normal text-xs text-romanSilver capitalize"},[_vm._v(" "+_vm._s(approver.designation)+" ")])])])]),_c('div',{staticClass:"flex flex-col justify-start items-end"},[_c('p',{staticClass:"font-normal text-xs capitalize",class:{
                      c_approved: approver.hasApproved === 'true',
                      c_disapproved: approver.hasApproved === 'false',
                      c_pending: approver.currentApprover === 'active',
                      c_inactive: approver.currentApprover === 'inactive',
                      c_cancelled: approver.currentApprover === 'cancelled',
                    }},[(approver.currentApprover === 'active')?_c('span',[_vm._v("Pending")]):_vm._e(),(approver.currentApprover === 'cancelled')?_c('span',[_vm._v("Cancelled")]):_vm._e(),(approver.currentApprover === 'inactive')?_c('span',[_vm._v("Not Started")]):_vm._e(),(approver.currentApprover === 'hasApproved')?[(approver.hasApproved === 'true')?_c('span',{staticClass:"text-mediumSeaGreen"},[_vm._v("Approved")]):_c('span',{staticClass:"text-desire"},[_vm._v("Disapproved")])]:_vm._e()],2),(approver.currentApprover === 'hasApproved')?[_c('p',{staticClass:"font-semibold text-xs uppercase text-jet"},[_vm._v(" "+_vm._s(_vm.$DATEFORMAT( new Date(approver.actionTakenAt), "MMMM dd, yyyy"))+" ")])]:_vm._e()],2)]),(approver.comment)?_c('div',{staticClass:"ml-12"},[_c('div',{staticClass:"comment-box text-xs"},[_c('p',{staticClass:"text-romanSilver text-xs"},[_vm._v("Comment")]),_c('p',{staticClass:"text-darkPurple"},[_vm._v(_vm._s(approver.comment))])])]):_vm._e()])])])],1)}),0)]:_vm._e(),(!_vm.approvers.length)?[_c('div',{staticClass:"flex flex-col justify-center items-center gap-5 px-10 py-20"},[_c('Icon',{attrs:{"icon-name":"paygrade_Illustration","size":"","class-name":"w-32 h-32"}}),_c('p',{staticClass:"font-normal text-base leading-5 text-jet text-center"},[_vm._v(" Review is not yet submitted for approval. Please kindly submit for approval to commence ")])],1)]:_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }